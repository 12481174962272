:root {
  @apply light;
}

.dark {
  --color-bg-primary: #2d3748;
  --color-bg-secondary: #283141;
  --color-text-primary: #f7fafc;
  --color-text-secondary: #e2e8f0;
  --color-text-accent: #81e6d9;
}

.light {
  --color-bg-primary: #f2ede9;
  --color-bg-secondary: #f2ede9;
  --color-text-primary: #2d3748;
  --color-text-secondary: #4a5568;
  --color-text-accent: #2b6cb0;
}
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('../assets/font/SpaceGrotesk-Regular.woff2') format('woff2'), url('../assets/font/SpaceGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SpaceGrotesk-Medium';
  src: url('../assets/font/SpaceGrotesk-Medium.woff2') format('woff2'), url('../assets/font/SpaceGrotesk-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SpaceGrotesk-Bold';
  src: url('../assets/font/SpaceGrotesk-Bold.woff2') format('woff2'), url('../assets/font/SpaceGrotesk-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.hefm-full-layout-background {
  background-size: cover !important;
}
.hefm-content {
  min-height: 68vh;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
